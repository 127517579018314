.copy-button-wrapper{
  height: auto;
  background: unset !important;
  display: flex;
  justify-content: flex-end;
  outline: unset !important;
  
  &::before{
    display: none !important;
  }
  &:active{
    transform: translateY(2px);
  }
  span{
    display: none !important;
  }

}