@import "./../vars.scss";

.bx--modal--mobile--menu{
  .bx--modal-header{
    background: $background-primary;
    padding: 10px 48px 10px 42px;
  }
}

.modal-header__with-export-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}