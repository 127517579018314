.analytic-period {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__toggle {
    max-width: max-content;
    display: flex;
    gap: 10px;
  }

  &__buttons {
    padding: 4px;
    width: 378px;
    height: 40px;
    background: #F3F5F6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    background-color: transparent;
    border: 0;
    padding: 6px 20px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5B6871;

    &--active {
      background: #FFFFFF;
      box-shadow: 0px 2px 10px rgba(54, 83, 99, 0.1);
      border-radius: 4px;
      font-weight: 600;
      color: #252C32;
    }
  }

}

.total-volume {
  &__container {
    width: 100%;
    padding: 20px 0;
    display: flex;
    gap: 107px;
  }

  &__item {
    display: grid;
    grid-template-rows: 1fr auto auto;

    &-percantage {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      &--positive {
        color: #2FA53A;
      }
      &--negative {
        color: #F53F3F;
      }
    }

    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #5B6871;
    }

    &-sub-title {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #5B6871;
    }

    &-amount {
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.8px;
      color: #252C32;
    }
  }
}

.custom-tooltip {
  background-color: #FFFFFF;
  padding: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid #CFD7DD;
  box-shadow: 0px 0px 5px rgba(54, 83, 99, 0.1), 0px 25px 30px rgba(54, 83, 99, 0.1);
  border-radius: 4px;
  min-width: 150px;
  gap: 6px;

  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #252C32;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #252C32;
    gap: 32px;

    &-name {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }


  &__divider {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #5B6871;
    position: relative;

    &::after {
      position: absolute;
      right: 0;
      content: '';
      width: 55%;
      border: 1px dashed #CFD7DD;
    }
  }
}

.quantity-icon {
  position: relative;
  transform: translate(-1px, -4px);
  width: 12px;
  height: 2px;
  left: 4px;
  top: 5px;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;

  & div {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

}

.custom-legend {
  &__container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 25px;
    row-gap: 10px;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;

    &--icon {
      width: 18px;
      height: 10px;
      border-radius: 2px;
      margin-right: 8px;
    }
  }
}