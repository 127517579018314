.dots-menu {
  z-index: 4000;
  &__item {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 7px;
    &--danger {
      color: $danger-btn;
      svg {
        fill: $danger-btn;
      }
    }
    &--secondary {
      color: $secondary-btn;
      svg {
        fill: $secondary-btn;
      }
    }
  }

  &__max-width {
    width: max-content;

    & .bx--overflow-menu-options__btn {
      max-width: 100%;
      min-width: 11.25rem;
    }
  }
}