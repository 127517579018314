.file-uploader {
  &__container {
    height: 50px;
    width: 100%;
  }

  &__item {
    width: 100%;
    
    .bx--file__selected-file {
      max-width: 100%;
    }
  }
}

.bx--file{
  &-browse-btn {
    max-width: 100%;
  }
}