@import "styles/vars.scss";
.mobile-menu-bottom{
  position: sticky;
  z-index: 9000;
  bottom: 0;
  background-color: $background-default;
  box-shadow: 0px -1px 0px $hover-secondary;
  display: flex;
  padding: 10px;
  
  div{
    color: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6.5px;
  }
  span{
    font-size: 10px;
    line-height: 16px;
  }

  @media screen and (min-width: 672px){
    display: none;
  }
}