.bx--toast-notification {
  width: 100%;
  align-items: center;

  &-container {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 8000;
    width: 100%;
    max-width: 560px;
  }

  &__icon {
    margin: 0 1rem 0 0;
  }

  &__title,
  &__subtitle {
    margin: 0;
  }

  &__caption {
    margin: 0;
    padding: 15px 0;
  }

  &--error {
    border-left: 3px solid $brand-error-color;
    background: $brand-error-color;

    .bx--toast-notification__icon {
      fill: #FFF;
    }
  }

  &--success {
    border-left: 3px solid $brand-success-color;
    background: $background-secondary;

    .bx--toast-notification__icon {
      fill: $brand-success-color;
    }
  }
}

@media (max-width: $mobile-menu-breakpoint) {
  .bx--toast-notification-container {
    top: 2rem;
    bottom: auto;
    left: 0;
  }
}