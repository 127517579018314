.comment-tooltip {
  background-color: #fff;
  padding: 8px 16px;
  box-shadow: 0px 25px 30px 0px rgba(54, 83, 99, 0.10), 0px 0px 5px 0px rgba(54, 83, 99, 0.10);
  z-index: 7999;


  &__cell {
    height: auto;
    white-space: initial;
    cursor: pointer;

    &-fees {
      display: flex;
      justify-content: space-between;
    }

    &:hover .comment-tooltip--comment {
      color: $brand-color-blue-hover;
    }

  }

  &--comment {
    display: flex;

    &-cell-comment {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-bottom: 1px;
    }
  }

  &--item {
    text-align: left;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    word-break: break-word;

    &--with-download {
      justify-content: initial;
    }

    &__download-button {
      padding: 0px !important;

      &:hover svg.bx--btn__icon path {
        cursor: pointer;
        fill: $brand-color-blue-hover  !important
      }
    }

    .bx--btn {
      height: max-content;

      &.bx--btn--icon-only.bx--tooltip__trigger:focus {
        border: none;
        outline: none;
      }

      &--ghost {
        box-shadow: none
      }
    }

    .bx--tooltip__label {
      height: 1px;
      display: inline;
    }

    &-value {
      color: $text-primary;
    }
  }

  &--container {
    width: 100%;
  }

  .bx--tooltip__content {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $text-secondary;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    
  }

  & .bx--tooltip__caret {
    border-bottom-color: #fff
  }
}

.bx--tooltip__label {
  height: 1px;  
  display: inline;
}
