.bx--checkbox {
  &:focus {
    + .bx--checkbox-label {
      &:before {
        outline: 2px solid $brand-color;
        border-color: $brand-color;
      }
    }
  }

  &:checked {
    + .bx--checkbox-label {
      color: $text-primary;

      &:before {
        border-color: $brand-color;
        background-color: $brand-color;
      }
    }

    &:focus {
      + .bx--checkbox-label {
        &:before {
          outline: 2px solid $brand-color;
        }
      }
    }
  }

  &-label {
    min-height: 1.125rem;
    color: $text-secondary;

    &:before {
      width: 15px;
      height: 15px;
    }

    &:after {
      top: 7px;
      width: 9px;
      height: 5px;
    }

    &[data-contained-checkbox-state=true] {
      &:before {
        border-color: $brand-color;
        background-color: $brand-color;
      }
    }
  }
}

.bx--checkbox:indeterminate + .bx--checkbox-label::before {
  border-color: $brand-color;
  background-color: $brand-color;
}

.bx--checkbox:indeterminate + .bx--checkbox-label::after {
  width: 0.45rem;
}

.checkbox--with-margin-bottom {
  margin-bottom: 18px;

   .bx--checkbox-label-text {
    font-weight: bold;
   }
}