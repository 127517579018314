.overflow-clear-update-menu {
  &__container {
    position: relative;
  }

  &__button {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    bottom: 0px;
    padding-bottom: 6px;
    font-size: 16px;
    font-weight: bold;
    right: -4px;
    letter-spacing: 1px;
  }

  &__content {
    position: absolute;
    z-index: 99;
    left: -85px;
    top: 16px;
    background: #fff;
    border: 1px solid #ddd;
    filter: drop-shadow(0px 0px 5px rgba(54, 83, 99, 0.1)) drop-shadow(0px 25px 30px rgba(54, 83, 99, 0.1));
    display: flex;
    min-width: 190px;
    padding: 8px 0px;
    flex-direction: column;
  }

  &__item {
    display: flex;
    // align-items: center;
    height: 36px;
    padding: 9px 16px;
    gap: 16px;

    &:hover {
      background: #F3F5F6
    }

    &--danger {
      color: $danger-btn;
      svg {
        fill: $danger-btn;
      }
    }
    &--secondary {
      color: $secondary-btn;
      svg {
        fill: $secondary-btn;
      }
    }

  }
}
