.divider {
  width: 100%;
  height: 1px;
  margin: 5px 0;
  background: $ui-03;
}

.align-divider {
  &--center {
    display: flex;
    align-items: center;
  }
}