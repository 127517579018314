.bx {
  &--toggle {
    &__switch {
      margin-right: $carbon--spacing-03;
      margin-top: 0 !important;
    }
    &-input {
      &__label {
        flex-direction: row-reverse;
        align-items: center;
      }
    }
  }
}
.toggle-custom{
  position: relative;
  input{
    left: 0;
  }
}
.toggle-small-custom{
  position: relative;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  input{
    left: 0;
  }
}

.align-toggle {
  display: flex;
  align-items: flex-end;
  height: 58px;
}