@import "./../vars.scss";

.structured-list {
  display: flex;
  border: 1.5px $brand-color dashed;
  justify-content: space-between;
  padding: 7px 16px;
  margin-bottom: 16px;
  background-color: #fff;
  align-items: center;

  &--border:not(:last-child) {
    border: 1.5px solid $border-primary;
    transform: rotate(90deg);
    width: 28px;
  }

  &--title {
    display: flex;
    padding: 11px 0;
    color: $text-primary;
    font-weight: 600;
  }

  &--row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 42px;

    &__title {
      color: $text-secondary;
      line-height: 20px;
    }

    &__value {
      color: $text-primary;
      line-height: 18px;
    }
  }
}