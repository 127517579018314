.search-with-field-select__container{
  display: grid;
  grid-template-columns: 2fr 1fr auto;

  margin-right: auto;
  width:100%;
  .bx--search{
    flex-grow: 3;
    input{
      padding: 14px 45px 14px 16px;
    }
  }
  .bx--dropdown__wrapper{
    margin-left: 1px;
    flex-grow: 1;

  }
}

.max-width-select-with-search {
  max-width: 37%;

  & .bx--dropdown__wrapper, .bx--list-box__wrapper {
    width: 100%;
  }

  & .bx--list-box__field button {
    padding-right: initial;
  }
  
  & .bx--text-input {
    width: initial;
    &::placeholder {
      text-overflow: ellipsis;
      width: 100%;
    }
    
  }
}