.description-list {
  display: grid;
  grid-template-columns: repeat(auto-fill,50%);
  grid-row-gap: 10px;

  & > .description {
    border-bottom: 1px solid $ui-01;
    padding: $carbon--spacing-02;
  }
}

@media (max-width: $mobile-menu-breakpoint) {
  .description-list {
    padding: 10px 16px;
  }
}