@import "styles/vars.scss";

.bx {
  &--form {
    &__item {
      margin: $carbon--spacing-02 0;
    }
  }

  &--label {
    margin-bottom: $carbon--spacing-01;
    // display: inline;
  }

  &--list-box {
    &__wrapper {
      display: block;
      &--inline {
      }
    }
  }
}

.bx--modal-content__body .submit-button-modal .bx--btn {
  position: absolute !important;
  bottom: 12px;
  right: 24px;
}

.bx--modal-content__footer .cancel-modal-button {
  position: absolute;
  bottom: 12px;
  width: 148px
}

@media (max-width: $mobile-menu-breakpoint) {
  .dropdown-heading {
    pointer-events: none;
    padding-right: 16px;
  }

  .dropdown-heading svg {
    transform: rotate(-90deg);
  }

  .bx--form__item {
    word-break: break-word;
  }

  .bx--modal-content__body .bx--form__item .bx--btn--secondary {
    position: absolute;
    z-index: 9100;
    bottom: 10px;
    right: 20px;
    width: 45vW;
  }

  // .bx--btn {
  //   padding: 5px;
  // }

  .bx--row--condensed .bx--col, .bx--grid--condensed .bx--col:not(:last-of-type) {
    padding-left: 16px;
    border-bottom: 1px solid #E5E9EB;
  }  

  .mobile-filter {
    padding-left: 16px !important;
  }  

  .bx--modal-content__body {
    padding: 0;
  }


  .bx--modal-content__body .maxWidthContent {
    max-width: initial;
  }

  .multi-select__wrap .rmsc .dropdown-container {
    padding-right: 6px;
  }

  .button-create__link.bx--btn {
    &--primary {
      min-width: 40px;
      padding: 10px;
      -webkit-tap-highlight-color: transparent;

      @include button-theme-custom(
        $btn-text-main-color,
        $btn-text-main-color,
        $background-primary,
        transparent,
        transparent,
        $hover-secondary,
        transparent,
        transparent
      );

      &:focus {
        box-shadow: none;
      }

      & .bx--btn__icon {
        position: static;
        margin: 0;
      }
    }
  }

  .rmsc .dropdown-content .panel-content {
    box-shadow: none !important;
  }
}

.form-item__table-header {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 16px;
    color: $text-primary;
    font-weight: 600;
    font-size: 16px;
  }

  &-add-btn {
    color: $secondary-btn;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 400;
    font-size: 14px;
  }
}

.status_reason {
    &__absolut_button {
      position: absolute;
      bottom: -65px;
  }

    &__table-container {
      margin-bottom: 20px;
  }
}
