@import "styles/vars.scss";

.json-editor {
  position: relative;
  
  &-container {
    height: 300px;

    &-modal {
      height: 100vh;
    }
  }

  &__resize-icon {
    position: absolute;
    right: 4px;
    z-index: 99;
    top: 16px;
    background-color: white;
    padding: 2px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: $dropdown-box-shadow;
    transition: transform 0.3s;
    
    &:hover {
      transform: scale(1.2);
    }
  }

}


