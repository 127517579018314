.tree-view-rule {
  background-image: url(../../images/dotsBg.svg);
  background-repeat: repeat;
  // width: max-content;
  min-width: 104%;
  overflow: scroll;
  height: 100%;
  min-height: 586px;
  margin-left: -1.4rem;
  margin-top: 26px;
  padding: 1rem 5rem 3rem 1rem;

  &__title {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.4px;
    margin-bottom: 4px;
  }

  &__save-changes-btn{
    position: absolute;
    bottom: 20px;
  }
  &__divider {
    margin-bottom: 100px
  }

  &__information {
    display: flex;
    color: $text-secondary;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    font-weight: 400;
    line-height: 16px;

    svg {
      color: black;
    }
  }

  &__blocks-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: max-content;

    &--disabled {
      pointer-events: none;
    }
  }

  &--add-button {
    border-radius: 34px;
    border: 1px solid  #CFD7DD;
    background:  #FFF;
    box-shadow: 0px 2px 6px 0px rgba(32, 37, 50, 0.01), 0px 4px 3px 0px rgba(32, 37, 50, 0.01);
    width: max-content;
    color: #000;

    display: flex;
    align-items: center;
    gap: 2px;
    padding: 8px;
  }
}

.add-conditions-button {
    border-radius: 50%;
    border: 1px solid  #CFD7DD;
    background:  #FFF;
    box-shadow: 0px 2px 6px 0px rgba(32, 37, 50, 0.01), 0px 4px 3px 0px rgba(32, 37, 50, 0.01);
    width: 28px;
    height: 28px;
    color: #000;
    padding: 6px;
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;

    &__container {
      position: relative;
      max-width: max-content;
    }

    &--with-text {
      width: max-content;
      display: flex;
      align-items: center;
      border-radius: 34px;
      padding: 8px;
      height: auto;
      gap: 2px;
    }

    &__overflow-panel {
      position: absolute;
      z-index: 99;
      left: 100%;
      top: 0px;
      background: #fff;
      padding: 5px;
      border: 1px solid #ddd;
      display: flex;
      width: 190px;
      padding: 8px 0px;
      flex-direction: column;

      &-item {
        padding: 8px;
        padding-left: 16px;
        color: $text-primary;
        font-weight: 400;
        cursor: pointer;
        &:hover {
          background-color: $hover-primary;
        }
      }
    }
  }

  .operator-changer {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #FFF;
      padding: 2px;
      border-radius: 4px;
      width: 54px;
      gap: 2px;
    }

    &-checked {
      font-weight: 700;
      border: 1px solid $tertiary-btn-hover;
      color: $text-primary !important;
    }

    &-item {
      padding: 4px 0px;
      width: 50px;
      text-align: center;
      border-radius: 4px;
      background: #FFF;
      text-transform: uppercase;
      color: $text-secondary;
      cursor: pointer;
    }

    &-in-row {
      flex-direction: row;
      width: initial;
      background-color: $tertiary-btn-hover;

      & .operator-changer {
        &-item {
          background: transparent;
        }

        &-checked {
          background: #FFF
        }
      }
    }
  }