@import "styles/vars.scss";

.update-settings-content{
  .multi-select__wrap{
    background-color: $background-default;
    .dropdown-container{
      padding: 0 16px;
    }
    .multi-select__amount{
      margin-left: 8px
    }
  }
  
  @media (max-width: $mobile-menu-breakpoint) {
    .multi-select__header-container{
      align-items: center;
    }
  }
}

