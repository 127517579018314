.fields-array {
  width: 100%;
  margin: $carbon--spacing-03 0;

  .field-with-btn {
    display: inline-flex;
    width: 100%;
    &__btn {
      margin-left: 20px;
      padding: 13px 14px;
      border-radius: 48px;
      margin-top: 22px;
      cursor: pointer;
      height: 48px;
      background: rgba($danger-btn, 0.1);
  
      svg {
        fill: $danger-btn;
      }
  
      &:hover {
        background: rgba($danger-btn, 0.14);
        svg {
          transform: scale(1.2);
          transition: all 0.2s;
        }
      }

      &--confirm {
        background: rgba($primary-btn, 0.40);
  
        svg {
          fill: $background-toggle;
        }
  
        &:hover {
          background: rgba($primary-btn, 0.45);
        }
      }

      &.disabled-btn {
        background: rgba($input-background-disabled, 0.2);
        cursor: not-allowed;

        svg {
          fill: $input-background-disabled;
        }

        &:hover {
          svg {
            transform: none;
          }
        }
      }
    }
  }

  &__top {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    margin-bottom: 5px;
    align-items: center;

    &__divider {
      width: 38px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 1px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $ui-03;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $text-primary;
    }

    &__add {
      color: $secondary-btn;
      cursor: pointer;
    }
  }

  &__remove-button {
    padding-right: 0px !important;
    max-width: max-content;
    &:hover button svg {
      color: red
    }
  }
}