.bx--list-box {
  &.bx--list-box--inline .bx--list-box__field {
    padding: 0 2rem 0 0;
  }
  &__label {
    color: $text-secondary;
  }
  &__field {
    &:focus {
      outline: 2px solid $brand-color;
    }
  }

  &__menu {
    box-shadow: $dropdown-box-shadow;
    &:focus {
      outline: none;
    }
  }

  &__menu-item {
    &:hover {
      background-color: $hover-primary;
    }
  }

  .bx--list-box--light & {
    width: fit-content;

    &__menu {
      background: $dropdown-primary-background;
    }

    &__menu-item {
      &--active {
        background-color: transparent;

        .bx--checkbox-label {
          color: $text-primary;
        }
      }
    }
  }

  .bx--list-box--xl &,
  .bx--list-box--lg & {
    &__menu-item {
      height: 2.5rem;

      &:hover {
        background-color: $hover-primary;
      }

      &__option {
        height: 2.5rem;
        padding-top: 11px;
        padding-bottom: 11px;
        border: none;
        color: $text-secondary;
        line-height: 18px;
      }

      &--active.bx--list-box__menu-item--highlighted {
        background-color: $background-primary;
      }
    }
  }
}

.bx--dropdown {
  border-bottom: 1px solid $input-border-color;
  color: $text-primary;

  &:hover {
    border-bottom: 1px solid $input-hover-border-color;
    background: #fff;
  }

  .bx--list-box__field {
    &:focus {
      outline: 2px solid $input-hover-border-color;
    }
  }

  &--open {
    .bx--list-box__field {
      outline: 2px solid $input-hover-border-color;
      outline-offset: -2px;
    }

    .bx--list-box__menu {
      &:focus {
        outline: none;
      }
    }
  }
}

.bx--list-box input[role=combobox], .bx--list-box input[type=text] {
  color: $text-secondary;
}
