.change-value-dialog-window {
  position: absolute;
  min-width: 200px;
  width: max-content;
  max-width: 810px;
  background-color: #fff;
  z-index: 7999;
  top: -165px;
  left: -100px;  
  box-shadow: 0px 25px 30px 0px rgba(54, 83, 99, 0.10), 0px 0px 5px 0px rgba(54, 83, 99, 0.10);
  padding: 10px 0px 10px 16px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 20px;
    right: -30px;
  }


  &__container {
    position: relative;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    padding: 2px;
    width: 90%;

    &:hover {
      color: $brand-color-blue-hover;
    }
  }

  &__title {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.4px;
  }

  &__input-weightValue {
    margin-right: -8px !important;

    &::before {
      content: '=';
      color: black;
      font-size: 14px;
      margin: 0 16px 18px 0px;
    }

    & .bx--text-input {
    background-color: $input-background-inverted;
  }}
}

.form:has(.bx--row > .fields-array.change-value-dialog-window--weight)  {
  max-width: 778px
}

.form:has(.bx--row > .fields-array.change-value-dialog-window--priority)  {
  max-width: 622px
}
.form:has(.bx--row > .change-value-dialog-window--direct)  {
  max-width: 368px
}

.minWidthSelect {
  min-width: 200px;
}

.strategy-select {
  max-width: 368px;
}