.not-closed {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-bottom: 30px;
  }

  &__icon {
    color: $brand-color;
    height: 56px;
    width: 56px;
  }

  &__prompt {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.6px;
    color: $text-primary;
    margin-bottom: 8px;
    padding-top: 10px;

    &-provider {
      text-decoration: underline;
    }

    &-secondary {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $text-secondary;
    }
  }



  &__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}