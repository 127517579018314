.bx{
  &--link-panel {
    margin: $carbon--spacing-02;
  }

  &--link {
    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4px;
      color: $text-primary;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title-container {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    &__icon {
      margin-left: 14px;
    }

  }


}