@import "styles/vars.scss";
.bx--data-table {
  tbody {
    tr {
      &:hover {
        background: $hover-primary;

        td {
          background: $hover-primary;
          color: $text-secondary;

          .bx--link {
            color: $brand-color;
          }
        }
      }
    }

  }

  tr {
    height: 4rem;
  }

  td {
    color: $text-secondary;

    .cell-wrapper {
      display: flex;
      align-items: center;

      > span {
        margin-right: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.bx--table {
  &-header-label {
    line-height: 20px;
  }
}

.bx--table-sort  {
  &:focus {
    outline: none;
  }
}

.action-cell {
  display: flex;
  align-items: top;
  gap: 2px;
  font-size: 14px;
  
 &--open {
   height: auto;
   white-space: initial;
   overflow-wrap: anywhere;
 }
}

@media (max-width: $mobile-menu-breakpoint) {
  .action-cell {
      display: block;
  }
}
