.change-value-tooltip--cell {
  height: auto;
  white-space: initial;
  // text-align: right;

  &:hover {
    color: $brand-color;
    cursor: pointer;
  }

  &__comment {
    display: grid;
    grid-template-columns: 16px auto;
    grid-gap: 10px;
  }

  &__adding {
    height: 200px;
    width: 200px;
    position: fixed;
    z-index: 6000;
    background-color: #fff;
  }
}

.popover-tooltip {
  background-color: #fff;
  padding: 11px 16px 8px;
  box-shadow: 0px 0px 5px rgba(54, 83, 99, 0.1), 0px 25px 30px rgba(54, 83, 99, 0.1);
  z-index: 8000;
  &-modal {
    z-index: 9001 !important;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: $text-primary;
    text-align: left;
  }

  &__saveBtn {
    position: relative;
    left: 340px;
    max-width: max-content;

    &-big {
      max-width: max-content;
      position: relative;
      left: 466px
    }
  }

  & .bx--tooltip__caret {
    border-bottom-color: #fff
  }
}

.mb--cell {
  height: auto;
  white-space: initial;

  &-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $text-secondary;
  }
}

.exchange-rates--cell {
  padding: 10px 4px ;
  height: auto;
  white-space: initial;

  &-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $text-secondary;
  }
}

.align-input {
  display: flex;
  align-items: flex-end;

  & .bx--dropdown__wrapper  {
    flex-shrink: 0;
  }
  
}

.plus-before::before {
  content: '+';
  color: black;
  margin: 0 4px 17px;
}

.one-before::before {
  content: '1';
  color: black;
  font-size: 30px;
  margin: 0 4px 4px;
}

.equalOne-before::before {
  content: '= 1';
  color: black;
  font-size: 30px;
  width: inherit;
  flex-shrink: 0;
  padding-left: 28px;
  max-width: max-content;
  margin: 0 4px 4px 10px;
}

.max-width-input {
  max-width: 37%;


  & .bx--dropdown__wrapper, .bx--list-box__wrapper {
    width: 100%;
  }

  & .bx--list-box__field button {
    padding-right: initial;
  }

}

.bigTooltipClass {
  min-width: 550px;
}
.smallTooltipClass {
  min-width: 422px;
}

.change-value--with-atachment {
  &__container {
    display: flex;
    gap: 6px;
    align-items: flex-start;
  }
  &__icon {
   padding-bottom: 2px;

   &:hover {
    fill: $brand-color-blue-hover
  }
  }

}