.month-picker {

  &__container {
    position: absolute;
    background-color: #fff;
    max-width: 96%;
    display: flex;
    flex-direction: column;
    z-index: 8888;
  }

  &__years {
    padding: 10px 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }

  &__month {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    font-size: 16px;
    margin: 0 auto;
    justify-content: space-around;
  }


  &__carret {
    color: $text-primary;
    cursor: pointer;

    &--disabled {
      color: $text-secondary;
      cursor: not-allowed;
    }
  }

  &__item {
    padding: 10px;
    cursor: pointer;
    color: $text-primary;
    border-radius: 20px;
    text-align: center;
    min-width: 50px;
    flex-shrink: 0;

    &--selected {
      background-color: $brand-color;
      color: #fff;
    }

    &:hover {
      background-color: $brand-color-blue-hover;
      color: #fff;
      transition: 0.3s;
    }

  }
}


.small-input__container-left .month-picker__container {
  left: 10px;
}