@import "styles/vars.scss";

.date-range {
  position: relative;
  &__body {
    position: absolute;
    z-index: 999;
    border: 1px solid $border-primary;
    box-shadow: $date-range-box-shadow;

    button {
      padding-right: initial;
    }

    &__period {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: $input-background;
      padding: $carbon--spacing-03 0;
    }
    .rdrDateRangePickerWrapper {
      display: flex;
      flex-direction: column-reverse;
    }
    .rdrDefinedRangesWrapper {
      width: 100%;
    }
    .rdrStaticRanges {
      display: grid;
      grid-template-columns: repeat(6, 100px);
    }
    .rdrInputRanges {
      padding: 0;
    }

  }
  .rdrStaticRanges {
    .bx--radio-button__label {
      justify-content: left;
    }
  }
}

.date-calendar .rdrDateDisplayWrapper {
  display: none;
}

@media (max-width: $mobile-menu-breakpoint) {
  .date-range {
    &__body {
    position: static; 
    z-index: 9999;
    border: 0;
    box-shadow: none;

    &__period {
      display: flex;
      background: $input-background;
      padding: 0;
    }

    .rdrStaticRanges {
      display: flex;
    }
    }

    &-mobile {
    display: flex;
    flex-direction: column;

      &-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
      }
    }
  }

  .rdrStaticRange {
    border-bottom: 0 !important;
  }

  .bx--radio-button-wrapper .bx--radio-button__label {
    justify-content: start;
  }

  .rdrDateRangePickerWrapper .rdrCalendarWrapper {
    display: none;
  }

  .date__mobile-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    // padding: 0 16px;
  }

  .date__mobile-calendar {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 288px;
    height: 340px;
    border: 1px solid #E5E9EB;
  }

  .rdrWeekDays {
    background: #F0F3F5;
  }

  .rdrMonth {
    padding: 0 !important;
    width: initial !important;
  }

  .rdrDefinedRangesWrapper {
    border-right: initial !important;
  }

  .bx--radio-button-wrapper {
    padding: 13px 0;
  }

  .date-range__title {
    font-size: 14px;
    line-height: 18px;
    color: #5B6871;
  }

  .date-range__section {
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-bottom: 1px solid #E5E9EB;
    width: 100%;
    padding: 20px 16px;
  }

  .rdrStaticRangeLabel {
    padding: 13px 0 !important;
    font-size: 14px;
    line-height: 18px;
  }
}



