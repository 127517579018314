@import "./../vars.scss";

.multi-select {
  &__header-container {
    display: flex;
    margin-bottom: 3px;
  }
  &__amount {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-secondary;
    color: $background-default;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-right: 8px;
    padding: 4px 8px;
    border-radius: 20px;
  }
  &__label {
    display: flex;
    align-items: center;
    
    &-title {
      color: $text-secondary;
      margin-bottom: 4px;
    }
  }
  &__wrap {
    &--menu-full-width {
      .bx--list-box__menu {
        width: fit-content;
      }
    }
    &--dropdown-full-width {
      .dropdown-content {
        width: 100% !important;
      }
      .dropdown-container {
        border-bottom: 1px solid $input-border-color !important;
      }
    }
  }
}

.multi-select__wrap--dropdown-full-width .rmsc .dropdown-heading   {
  ::-webkit-scrollbar {
    display: none;
  }
  .dropdown-heading-value {
    overflow: scroll;
  }
}

.multi-select__wrap {
  .rmsc {
    .dropdown-content {
      width: max-content;
      z-index: 100;
    }
    .dropdown-container {
      background-color: transparent;
      border: none;
      border-radius: 0;
      .dropdown-heading {
        height: 3rem;
        cursor: pointer;
        padding-left: 0;
      }
    }
    .dropdown-container[aria-expanded=true] {
      .dropdown-heading {
        & svg {
          transform: rotate(180deg);
          transition: transform 110ms;
        }
      }
    }
    .dropdown-container:not([aria-expanded=true]) {
      &:hover {
        background-color: $background-dropdown;
      }
    }
    .search {
      border-bottom: 1px solid $dropdown-separator-color;
      position: relative;
      padding: 9px 7px;
      & input {
        padding-left: 25px;
        height: 28px;
        outline: 2px solid $brand-color;
      }
      & button {
        margin-top: 2px;
        margin-right: 2px;
      }
      &:before {
        content: url('../../images/search.svg');
        position: absolute;
        top: 15px;
        left: 10px;
        width: 16px;
        height: 16px;
      }
    }
    .options > label {
      background: transparent;
      border-bottom: 1px solid $dropdown-separator-color;
      margin-bottom: 7px;
      &:hover  {
        background: transparent;
      }
      &:hover :not([aria-selected=true]) {
        span:before {
          background: $custom-checkbox-background-hover;
        }
      }
      &:not([aria-selected=true]) {
        .dropdown-content {
          display: block;
        }
        & span:before {
          display: flex;
          content: "-";
          width: 15px;
          height: 8px;
          font-weight: bold;
          align-items: center;
          justify-content: center;
          background: $custom-checkbox-background;
          left: 0;
          border-radius: 2px;
          position: absolute;
          color: $dropdown-primary-background;
          padding: 2px 0 5px 0;
          bottom: 0;
          font-size: 26px;
        }
        & input[type='checkbox'] {
          visibility: hidden;
        }
    }
    }

    .options {

      .item-renderer {
        display: flex;
        align-items: center;
        position: relative;
        & input {
          width: 15px;
          height: 15px;
        }
        & span {
          margin-left: 3px;
        }
      }
    }
  }
}

.multi-select__open {
  padding-bottom: 0px;
  .rmsc .dropdown-container .dropdown-heading {
    height: 10px;
    font-size: 12px;
  }
  .multi-select__amount {
    display: none;
  }
  svg {
    display: none;
  }
  .rmsc .dropdown-container:focus-within {
    box-shadow: none;
  }
  .rmsc .dropdown-container .dropdown-content {
    position: relative;
    width: inherit;
    padding-top: 0;
    .panel-content {
      box-shadow: none;
      border-radius: 0;
    }
  }
  .dropdown-heading{
    display: none !important;
  }
}
.multi-select{
  &--white-background{
  .dropdown-heading{
    background-color: $input-background;
  }
}
  &--grey-background{
  .dropdown-heading{
    background-color: $input-background-inverted;
  }
}
}

.multi-select__required-error{
  border: 2px solid $brand-error-color;
  &__text{
    color: $brand-error-color;
    font-size: 12px;
    margin-top: 4px;
  }
}

.multi-select__twoColumns{
  display: grid;
  grid-template: 
    "leftColumn rightColumn" 
    "bottomCounter bottomCounter";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 307px 36px;
  gap: 1px;
  
  .multi-select{
    grid-area: leftColumn;
    &___right-column__remover{
      display: flex;
      align-items: center;
      span{
        margin-left: 10px;
      }
    }

    &___right-column__counter{
      line-height: 26px;
    }
  }

  .multi-select___bottom-counter{
    grid-area: bottomCounter;
    background-color: $background-default;
    padding: 10px;
  }

  .multi-select___right-column{
    display: flex;
    flex-direction: column;
    background-color: $background-default;
    grid-area: rightColumn;
    
    &__counter{
      border-bottom: 1px solid $border-primary;
      padding: 10px 10px 10px 10px;
    }
    &__remover{
      border-bottom: 1px solid $border-primary;
    }

    ul{
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      max-height: 260px;
      li{
        display: flex;
        align-items: center;
        padding: 10px;
        height: 35px;
        cursor: pointer;
        &:first-child{
          margin-bottom: 7px;
          padding: 10px 10px 10px 10px;
          height:36px;
          svg{
            margin-left: unset;
          }
        }
        svg{
          display: unset;
          margin-left: auto;
        }
      }
    }
  }
  
}

@media (max-width: $mobile-menu-breakpoint) {
  .multi-select__label {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .multi-select__label :last-child {
    margin-left: 0 !important;
    color: #6C8593 !important;
  }

  .multi-select__label :first-child {
    color: #252C32 !important;
  }

}


//// treeview
.tree-view {

  &__container {
    min-height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #E5E9EB;
  }

  &__groupBy {
    border-bottom: 1px solid #E5E9EB;
    padding-left: 20px;
  }

  &__search .bx--search-input{
    padding: 0 3rem;
  }

  &__amount {
    height: 36px;
    padding: 8px 0px 8px 16px;
    color: $placeholder-color;
    border-top: 1px solid #E5E9EB;
  }

  &__multi-select {
    overflow: scroll;
    max-height: 300px;

    &__sub-item {
      padding-left: 60px;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 16px;


      &-container {
        display: flex;
        flex-direction: column;
      }
    }
  }
}