.radio-button {
  box-shadow: none !important;

  &__container {
    display: flex;
    align-items: center;
    height: 48px;
    gap: 8px;

    &-column {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
    }
  }

  &__label-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $text-secondary;
  }

  &__buttons {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $text-primary;
    
  }

  &__invalid {
    &__text{
      color: $brand-error-color;
      font-size: 12px;
      margin-top: -8px;
    }
  }
}

.bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance::before {
  background-color: $brand-color;
}

.bx--radio-button:checked + .bx--radio-button__label .bx--radio-button__appearance  {
  border-color: $brand-color;
}

.bx--radio-button:focus + .bx--radio-button__label .bx--radio-button__appearance  {
  border-color: transparent;
}

.radio-with-margin {
  margin-left: 24px !important;
}

@media (max-width: $mobile-menu-breakpoint) {
  .radio-with-margin {
    margin-left: 0px !important;
  }

  .radio-button__container {
    justify-content: space-between;
    flex-shrink: 0;
    gap: 2px;
  }
}

@media (max-width: 340px) {
  .radio-button__container {
    justify-content: space-between;
    flex-shrink: 0;
    gap: 2px;
    height: 80px;
  }

  .radio-button__buttons {
    flex-direction: column;
    align-items: flex-start;
  }
}
