.overflow-menu {
  &__amount {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-secondary;
    color: $background-default;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-right: 8px;
    margin-left: 8px;
    padding: 4px 8px;
    border-radius: 20px;
  }

  &__header-container {
    display: flex;
    margin-bottom: 3px;
    align-items: center;
  }

  &__content {
    position: absolute;
    z-index: 99;
    left: 7px;
    top: 32px;
    background: #fff;
    border: 1px solid #ddd;
    filter: drop-shadow(0px 0px 5px rgba(54, 83, 99, 0.1)) drop-shadow(0px 25px 30px rgba(54, 83, 99, 0.1));
    min-width: 256px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    padding: 9px 16px;

    &:hover {
      background: #F3F5F6
    }

    &-container {
      max-height: 292px;
      overflow: scroll;
    }
  }

  &-search-wrapper {
    padding: 8px 10px;
    

    .bx--search-input {
      padding-left: 40px;
      border: 1px solid $input-hover-border-color;
      height: 32px;
    }

    .bx--search-close {
      display: none;
    }
  }

  &__information {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $text-secondary;

    svg {
      color: #000;
    }
  }


}
