.bx--date-picker {
  &__input {
    &:focus {
      outline: 2px solid $brand-color;
    }
  }
}

.bx--date-picker__calendar {
  .bx--date-picker {
    &__weekdays {
      background-color: $background-primary;
    }
  }

  .flatpickr-day {
    border-radius: 50%;
    color: $btn-text-main-color;

    &.today {
      color: $brand-color;

      &:after {
        background-color: $brand-color;
      }
    }

    &.selected,
    &.selected.endRange.inRange {
      background-color: $brand-color;
      color: $btn-text-secondary-color;
    }

    &.selected.endRange.inRange,
    &:focus {
      outline: 2px solid $brand-color;
    }
  }
}
