.menu-full-screen{
  padding: 24px 18px;
  li{ 
    margin-bottom: 24px;
    &.menu-item-full-screen{
      display: flex;
      align-items: center;
    }
    &:last-child{
      margin-bottom: unset;
    }
    &.menu-with-sub-full-screen{
      margin-top: -20px;
      >svg{
        margin-bottom: -36px;
      }
      button{
        padding-left: 38px;

        &::before{
          border: unset;
          background-color: unset;
        } 
      }
      .bx--accordion__content{
        padding-top:24px;
        span{
          margin-left:36px
        }
      }
    }
    span{
      margin-left: 18px;
    }
  }
}