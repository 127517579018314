.tree-view-item {
  display: flex;
  flex-direction: column;
  height: 80px;
  border: 1px solid $border-primary;
  box-shadow: 0px 2px 6px 0px rgba(32, 37, 50, 0.01), 0px 4px 3px 0px rgba(32, 37, 50, 0.01);
  max-width: 272px;
  min-width: 160px;
  width: max-content;

  &__change-title {
    & input {
      height: 40px;
    }
    & button {
      height: 40px;
      min-height: 40px;
    }

  }

  &-authType{
    max-width: max-content;

    &__checkbox-container{
      display: flex;
    }
  }

  .bx--tooltip__trigger:not(.bx--btn--icon-only):focus {
    outline: none;
  }

  &__top {
    display: flex;
    padding: 10px 16px;
    justify-content: space-between;
    align-items: center;
    background: $border-primary;
    box-shadow: 0px 1px 0px 0px #E5E9EB;
    height: 40px;
    color: #000;
    font-weight: 600;
    line-height: 20px;
    gap: 10px;

    &-with-edit-icon {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 4px;
      max-width: 90%;

      svg {
        height: 16px;
        width: 16px;
        flex-shrink: 0;
      }

      &__icon-container {
        display: flex;
        align-items: center;
      }
      

      &-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 95%;
      }
    }

    &-remove-btn:hover {
      fill: red
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    background: #fff;
    color: $text-primary;
    height: 100%;
    max-height: 40px;
    gap: 8px;

    &--auth-type {
      display: flex;
      gap: 16px;
      align-items: center;
      
      & .bx--toggle-input ~ .bx--toggle-input__label {
        font-weight: 400;
      }
    }

    

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
    }

  }
}



.strategy, .button-container {
  position: relative;
  margin-bottom: 16px;

}

// .line::before {
//   content: "";
//   width: 15px;
//   height: 100px;
//   border-radius: 0 0 0 30px;
//   border-left: 1.5px solid red;
//   border-bottom: 1.5px solid red;
//   display: block;
//   position: absolute;
//   bottom: 38px;
//   left: -10px;
//   z-index: -1;

 
// }

.line {
  width: 15px;
  height: 70px;
  border-radius: 0 0 0 30px;
  border-left: 1.5px solid #FF666E;
  border-bottom: 1.5px solid #FF666E;
  display: block;
  position: absolute;
  bottom: 38px;
  left: -10px;
  z-index: -1;

  &--green {
    border-left-color: #6ACF84;
    border-bottom-color: #6ACF84;
    z-index: -2;
    height: 200px;
  }

  &--short {
    height: 70px;
  }
  
  &--long {
    height: 225px;
  }

  &--dashed {
    border-style: dashed;
  }


}

.button-container .line {
  bottom: 16px;
}

.attribute__container {
  display: flex;
  align-items: center;
  gap: 10px;
}

