@import "styles/vars.scss";

.card {
  &__body {
    &--without-filter {
      margin-top: 30px;
    }
  }
  &__head {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    margin-bottom: $carbon--spacing-03;

    &.card-with-extra{
      height: 48px;
    }
    a {
      color: $text-01;
      svg {
        margin-right: 15px;
      }
    }

    &__title {
      font-size: 28px;
      font-weight: bold;

      &-break-words {
        display: contents;
      }

      &-text {
        margin-bottom: 8px;
      }
    }

    &__subtitle {
      font-size: 12px;
      min-height: 16px;
      font-weight: 400;
      color: $text-03;
      margin-top: $carbon--spacing-03;
      svg {
        vertical-align: middle;
        margin-left: $carbon--spacing-03;
        color: $text-01;
        cursor: pointer;
        transition: all 0.2s linear;
        &:hover {
          transform: scale(1.1);
        }
      }

      &-text {
        font-size: 14px;
        color: $copyright-color;
        font-weight: 400;
        margin-top: 8px;
      }
    }

    &__extra {
      position: absolute;
      right: 0;
      top: 0;

      > .bx--btn {
        min-width: 195px;
        margin-left: 16px;
        padding-right: 50px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.card-wrap {
  padding-top: 64px;
}

@media (max-width: $mobile-menu-breakpoint) {
  .card__body--without-filter {
    margin-top: 0;
  }

  .card__head__title {
    max-width: calc(100vw - 42px - 3rem);
    display: flex;
    word-break: break-word;
  }

  .card__head__title--with__subtitle {
    flex-direction: column; 
}

  .card-with-extra {
    min-height: 48px;
    height: initial !important;
  }
}