@import "styles/vars.scss";

.bx--modal {
  &-header {
    padding: 1rem 3rem 0 1.5rem;

    &__heading {
      &__scale {
        position: absolute;
        top: 15px;
        right: 50px;
        cursor: pointer;
      }
    }
    &--deactivating-user-modal{
      font-weight: 700;
    }
  }
  &--full-screen {
    width: 100%;
    height: 100%;
    .bx--modal-container {
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    padding: 0;
    overflow: hidden;
    margin: 0;
    &__body {
      height: calc(100% - 70px);
      overflow: auto;
      padding: $carbon--spacing-03 $carbon--spacing-06 0;
    }
    &__footer {
      padding: $carbon--spacing-06;
      border-top: 1px solid $border-primary;
      display: grid;
      justify-content: flex-end;
      grid-template-columns: max-content max-content;
      grid-gap: 10px;
      align-content: center;
      height: 70px;
    }
  }

  &-close {
    &:focus {
      border-color: transparent;
    }
  }
  .deactivating-user-modal--button--icon{
    position: absolute;
    right: 15px;
    font-size: 24px;
  }
}

.bx-xxs-modal .bx--modal-container--xs {
  width: 368px;
}

@media (max-width: $mobile-menu-breakpoint) {
  .bx--modal--mobile--menu .bx--modal-container {
    top: 48px;
  }

  .bx--modal-close__icon {
    fill: $text-inverted;
  }

  .bx--modal--mobile--menu .bx--modal-close__icon {
    fill: $text-primary;
  }

  .bx--modal-header {
    background: $brand-color;
    border-color: $brand-color;
    height: 48px;
    margin-bottom: 0;

    &--default, &--filter, &--dateRange, &--filters, &--deleteWithCommentModal, &--createUpdateItem {
      color:$text-inverted;
      font-weight: 800;
      font-size: 18px;
      line-height: 28px;
    }
  }

  .bx--modal-content__body .panel-content {
    top: -40px;
    position: absolute;
    width: 100vw;
  }

  .bx--modal-content__body .rmsc .options {
    min-height: 80vh !important;
  }

  .bx--modal-content__body {
    overflow: hidden;
    overflow-y: scroll;
  }
}

.advancedModal .bx--modal-container  {
  max-height: 95%
}

.bx--body--with-modal-open .bx--overflow-menu-options {
  z-index: 9000;
}

.bx--body--with-modal-open .bx--overflow-menu-options[data-floating-menu-direction=bottom]::after {
  display: none;
}
