.modal-body-with-form {
  padding: 0 0 $carbon--spacing-05 0;
  color: $text-secondary;
  white-space: break-spaces;
}

@media (max-width: $mobile-menu-breakpoint) {
  .modal-body-with-form {
    padding: 1.5rem
  }
}