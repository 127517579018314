.history-link {
  display: flex;
  gap: 8px;
  cursor: pointer;

  &__text {
    color: $brand-color;
    text-decoration: underline;
  }

}